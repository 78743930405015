import styled from "styled-components";
import { TABLET_MAX_WIDTH, MOBILE_MIN_WIDTH } from "@constants";

export const AboutSectionWrapper = styled.div`
    height: 8.25rem;
    display: flex;
    justify-content: center;
    padding: 5rem 0;
    -webkit-mask-image: linear-gradient(
        0.5turn,
        rgba(0, 0, 0, 0),
        rgba(0, 0, 0, 1),
        rgba(0, 0, 0, 0)
    );
    overflow: hidden;

    @media only screen and (max-width: ${TABLET_MAX_WIDTH}px) {
        height: fit-content;
        display: block;
        -webkit-mask-image: none;
        overflow: visible;
        padding: 2.5rem 0;
    }
`;

export const AboutSectionContainer = styled.div`
    display: flex;
    flex-direction: column;
`;

export const Heading = styled.p`
    font-size: 2.5rem;
    line-height: 3.5rem;
    margin: 0 0 1.5rem 0;
    font-weight: 700;

    @media only screen and (max-width: ${TABLET_MAX_WIDTH}px) {
        font-size: 2rem;
        line-height: 2.5rem;
        margin-bottom: 1.5rem;
    }

    @media only screen and (max-width: ${MOBILE_MIN_WIDTH}px) {
        font-size: 1.5rem;
        line-height: 2.25rem;
    }
`;

export const ChangingTitleContainer = styled.div`
    height: 43.75rem;
    display: flex;
    flex-direction: column;
    justify-content: space-between;
    align-items: flex-end;
    margin-right: -4rem;

    &:first-child {
        transform: translateY(-0.6rem);
    }

    &:last-child {
        flex-direction: column-reverse;
        align-items: flex-start;
        margin-right: 0;
        margin-left: -4rem;
        transform: translateY(calc(-100% + 4.925rem));
    }
`;

export const MobileTitle = styled.p`
    font-weight: 700;
    font-size: 1.75rem;
    line-height: 2.5rem;
    color: #c4b180;
    margin: 0;

    @media only screen and (max-width: ${TABLET_MAX_WIDTH}px) {
        font-size: 2.25rem;
        line-height: 5.625rem;
    }

    @media only screen and (max-width: ${MOBILE_MIN_WIDTH}px) {
        font-size: 1.75rem;
        line-height: 2.5rem;
    }
`;

export const ChangingTitle = styled.p`
    text-transform: uppercase;
    font-weight: 700;
    font-size: 4rem;
    text-align: center;
    width: fit-content;
    line-height: 5.625rem;
    margin: 0;
    transform: translateY(1.875rem);

    @media only screen and (max-width: ${MOBILE_MIN_WIDTH}px) {
        font-size: 1.75rem;
        line-height: 2.5rem;
    }
`;

export const StaticTitle = styled.p`
    text-transform: uppercase;
    height: 100%;
    color: var(--main-gold);
    display: flex;
    align-items: center;
    font-weight: 700;
    font-size: 9rem;
    line-height: 5.625rem;
    margin: 0;

    @media only screen and (max-width: ${TABLET_MAX_WIDTH}px) {
        font-size: 2.25rem;
        line-height: 5.625rem;
    }

    @media only screen and (max-width: ${MOBILE_MIN_WIDTH}px) {
        font-size: 1.75rem;
        line-height: 2.5rem;
    }
`;
