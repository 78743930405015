import React, { useCallback, useEffect } from "react";
import useWindowDimensions from "@hooks/useWindowDimensions";
import gsap from "gsap";
import { ScrollTrigger } from "gsap/ScrollTrigger";
import { LayoutWrapper } from "../../../../styles/CommonStyles";
import {
    AboutSectionWrapper,
    AboutSectionContainer,
    ChangingTitleContainer,
    ChangingTitle,
    StaticTitle,
    Heading,
    MobileTitle,
} from "./AboutSection.style";

const firstChangingTitleOptions = ["Creating", "Uniting", "Delivering"];

const secondChangingTitleOptions = ["products", "people", "results"];

const AboutSection = () => {
    const { isDesktop } = useWindowDimensions();

    useEffect(() => {
        if (!isDesktop || isDesktop === undefined) {
            return;
        }

        gsap.registerPlugin(ScrollTrigger);

        const firstTitles = gsap.utils.toArray(".about-item__first");
        const secondTitles = gsap.utils.toArray(".about-item__second");

        gsap.timeline({
            scrollTrigger: {
                id: "about-wrapper",
                trigger: ".about-wrapper",
                pin: true,
                scrub: 1,
                end: () => `+=700`,
            },
        })
            .to(firstTitles, {
                yPercent: -227 * firstChangingTitleOptions.length,
            })
            .to(
                secondTitles,
                {
                    yPercent: 225 * secondChangingTitleOptions.length,
                },
                "<"
            );
    }, [isDesktop]);

    const generateComponents = useCallback((optionsList, className) => {
        const components = [];
        optionsList.forEach((_, index) => {
            components.push(
                <ChangingTitle className={className}>
                    {optionsList[index]}
                </ChangingTitle>
            );
        });

        return components;
    }, []);

    return (
        <>
            {!isDesktop ? (
                <LayoutWrapper
                    style={{
                        maxWidth: "none",
                    }}
                >
                    <AboutSectionWrapper>
                        <Heading>Our mission</Heading>
                        <AboutSectionContainer>
                            <MobileTitle>
                                Creating wow products
                                <br />
                                Uniting wow people
                                <br />
                                Delivering wow results
                            </MobileTitle>
                        </AboutSectionContainer>
                    </AboutSectionWrapper>
                </LayoutWrapper>
            ) : (
                <LayoutWrapper
                    className="about-wrapper"
                    style={{
                        maxWidth: "none",
                        height: "100vh",
                        display: "flex",
                        flexDirection: "column",
                        justifyContent: "center",
                    }}
                >
                    <Heading>Our mission</Heading>
                    <AboutSectionWrapper>
                        <ChangingTitleContainer>
                            {generateComponents(
                                firstChangingTitleOptions,
                                "about-item__first"
                            )}
                        </ChangingTitleContainer>
                        <StaticTitle>WOW</StaticTitle>
                        <ChangingTitleContainer>
                            {generateComponents(
                                secondChangingTitleOptions,
                                "about-item__second"
                            )}
                        </ChangingTitleContainer>
                    </AboutSectionWrapper>
                </LayoutWrapper>
            )}
        </>
    );
};

export default AboutSection;
